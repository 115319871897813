<template>
  <EvaluationOfferListBase
    :tender="evaluationStore.tender"
    :offers="evaluationStore.offers"
    :offersEvaluations="evaluationStore.offersEvaluations"
    :additionalHeaders="[]"
    :isLoadingOffers="evaluationStore.isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="evaluationStore.fetchAndSortOffers(calculator)"
    @updateTab="emit('updateTab', $event)"
  >
    <template #additional-columns></template>
    <template #tco></template>
    <template #bpq="{ offer, conditionalOffers, index }">
      <BpqDocumentationDialog
        :offer
        :conditionalOffers
        :index
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { EvaluationOfferListBase, useEvaluationStore } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import { getCalculator } from '@/services';

const emit = defineEmits<{
  updateTab: [string],
}>();

const calculator = getCalculator();

const evaluationStore = useEvaluationStore();

</script>
