<template>
  <v-row>
    <v-col class="d-flex" cols="12">
      <v-text-field
        density="compact"
        class="input-field"
        v-model="description"
        :placeholder="`Titel på ${kind}`"
        variant="outlined"
        flat
        :hide-details="true"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row class="mt-n5">
    <v-col>
      <v-spacer />
      <p v-if="!createAsTemplate">
        Giv dit indkøb en titel, gerne kort og sigende, så leverandørerne på aftalen får indtryk af, hvad dit indkøb omfatter. Du kan ændre titlen senere.
      </p>
      <p v-else>
        Navngiv din skabelon, gerne kort og præcist, så brugerne forstår hvad den indeholder.
      </p>
    </v-col>
  </v-row>
  <div class="d-flex flex-row mt-0 align-center">
    <h3>Kategori</h3>
    <DimsTooltip>
      <p>Vælg din bilkategori. Vær opmærksom på, at du kun kan vælge én kategori. Skal du indkøbe biler på tværs af kategorierne, skal du oprette flere indkøb.</p>
      <p>Begge kategorier indeholder biler med drivmidler som benzin, diesel, el, brint, gas, hybrid mv., samt diverse aftaler om reservedele, reparation, service og opbygninger i forbindelse med det konkrete indkøb.</p>
      <p>Du kan anskaffe fabriksnye biler og demobiler i begge kategorier.</p>
      <p>Du kan ændre kategori senere.</p>
    </DimsTooltip>
  </div>
  <VehicleSelection />

  <div class="d-flex flex-row mt-4 align-center">
    <h3>Tildelingskriterie</h3>
    <DimsTooltip>
      <p>
        Vælg det tildelingskriterie du vil evaluere de indkomne tilbud efter.
        Tildelingskriteriet afhænger som udgangspunkt af, hvorvidt køretøjet
        efter jeres egne retningslinjer udgør en arbejdsplads.
      </p>
      <ul>
        <li>
          Udgør køretøjet en arbejdsplads, skal du som udgangspunkt evaluere
          efter <strong>"Bedste forhold mellem pris og kvalitet (APV)"</strong>,
          dvs. på baggrund af evaluering af både pris og kvalitet, hvor kvalitet
          opgøres ved en arbejdspladsvurdering (APV).
        </li>
        <li>
          Udgør køretøjet <strong>ikke</strong> en arbejdsplads, skal du som
          udgangspunkt evaluere efter <strong>"Omkostninger (TCO)"</strong>,
          dvs. på baggrund af indkøbets TCO-pris (Total Cost of Ownership), som
          omfatter indkøbspris og livscyklusomkostninger.
        </li>
      </ul>
    </DimsTooltip>
  </div>
  <AwardCriteria />

</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '@dims/components';
import AwardCriteria from './steps/AwardCriteria.vue';
import VehicleSelection from './steps/VehicleSelection.vue';

const { createAsTemplate = false } = defineProps<{
  /** when set to true it will create a template instead of a draft */
  createAsTemplate?: boolean }>();
const store = useStore();

const description = computed({
  get() {
    return store.draftTender?.description ?? '';
  },

  set(desc: string) {
    if (store.draftTender) {
      store.setDraftTender({
        ...store.draftTender,
        description: desc,
      });
    }
  },
});

const kind = computed(() => (createAsTemplate ? 'skabelon' : 'indkøb'));

</script>
<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tooltip-content {
  max-width: 30vw;
}
</style>
