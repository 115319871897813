import { Question, DocumentDrafterQuestionnaire, QuestionnaireWrapper, RequestForOfferQuestionnaireValidator, Tender } from '@dims/components';
import { DateTime } from 'luxon';
import deliveryAgreementIds from '@/models/DeliveryAgreementIds';

export default class RequestQuestionnaireValidator implements RequestForOfferQuestionnaireValidator {
  questionnaire;

  constructor(readonly tender: Tender, questionnaire: DocumentDrafterQuestionnaire) {
    this.questionnaire = new QuestionnaireWrapper(
      this.tender.agreementConfiguration.questionnaires.requestForOffer,
      questionnaire,
    );
  }
  get incomplete() {
    return !this.questionnaire.content.complete;
  }

  get numericValidators() {
    return {
      [deliveryAgreementIds.idDeadlineQuestions]: { min: 7, max: undefined },
      [deliveryAgreementIds.idDeliveryDaysAfterContract]: { min: 1, max: undefined },
      ['default']: { min: 0, max: undefined },
    };
  }

  minDate(question: Question): string | undefined {
    if (question.questionId === deliveryAgreementIds.idDeliveryDate) {
      return this.tender.deadlineForTender ?? undefined;
    }
    if (question.dataFieldType === 'Date') {
      return DateTime.local().toISODate();
    }
    return undefined;
  }

  get isSubcriteriaValid() {
    if (this.tender.awardCriteriaType === 'TCO') { return true; }
    return this.sumOfSubCriteria === 100;
  }

  get sumOfSubCriteria() {
    const sum = (arr: number[]) => arr.reduce((previousq, q) => previousq + q, 0);
    const weightQuestionIds = [
      deliveryAgreementIds.idDriverSeatWeight,
      deliveryAgreementIds.idEnterExitWeight,
      deliveryAgreementIds.idInstrumentationWeight,
      deliveryAgreementIds.idDrivingWeight,
      deliveryAgreementIds.idComfortWeight,
      deliveryAgreementIds.idViewWeight,
      deliveryAgreementIds.idNoiseWeight,
      deliveryAgreementIds.idSecurityWeight,
    ];
    const values = this.questionnaire.content.questions
      .filter((q) => weightQuestionIds.includes(q.questionId))
      .map((q) => (q.answers?.[0] && q.answers[0].value !== '' ? parseFloat(q.answers[0].value) : 0));
    return sum(values);
  }

  get subCriteriaValidation() {
    if (this.isSubcriteriaValid) {
      return null;
    }
    const sum = this.sumOfSubCriteria;
    return { message: `Summen af delkriterierne skal være 100% (Summen er ${sum})` };
  }
}
