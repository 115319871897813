<template>
  <OfferSandboxBase
    @reloadOfferSample="createSandboxOfferQuestionnaire()"
    backgroundColor="white"
  >
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 bg-canvas">
            <v-card-text>
              <div class="d-flex">
                <div>
                  <div class="d-flex align-center">
                    <h1 class="mt-0 mb-2 font-weight-bold">
                      Udfyld dit tilbud
                    </h1>
                    <v-chip
                      color="darkPurple"
                      class="ml-2"
                    >Leverandørvisning</v-chip>
                  </div>

                  <div class="mt-2 mb-7">
                    <p>Tilbudsafgivelsen herunder udgør dit tilbud</p>
                    <p>
                      Færdiggør dit tilbud ved at besvare (1) udfyld tilbud, (2)
                      bekræfte at dit tilbud opfylder kundens krav, (3) uploade
                      ESPD, (4) kontroller og send dit tilbud.
                    </p>
                  </div>
                </div>
              </div>
              <v-card>
                <v-card-text class="pa-10">
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="1"
                        :complete="isSectionComplete([priceSection])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Tilbudsafgivelse</h2>
                      <p>
                        Forud for tilbudsafgivelsen skal du orientere dig i
                        kundens offentliggjorte materiale, som du finder under
                        "overblik"
                      </p>
                      <p>
                        Du udfylder dit tilbud ved at besvare, tilbyde relevante
                        biler, produkter og service (herunder priser)
                      </p>
                      <p>
                        Du vil løbende blive guidet til at udfylde de relevante
                        felter og vejledt om, hvor du finde de relevante
                        informationer i kundens offentliggjorte materiale.
                      </p>
                      <v-dialog v-model="offerFormDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{
                              secondary: isSectionComplete([priceSection]),
                            }"
                          >Udfyld tilbud
                          </ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender
                            :offerQuestionnaire
                            :tabType="'SupplierOffer'"
                            :readonly="true"
                            @closeDialog="closeOfferFormDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="2" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                      <div>
                        Bekræft om dit tilbud opfylder samtlige krav i kundens
                        kravspecifikation.
                      </div>
                      <v-radio-group hide-details disabled density="compact">
                        <v-radio :value="true">
                          <template #label>
                            <small>Ja, alle krav er opfyldt</small>
                          </template>
                        </v-radio>
                        <v-radio :value="false">
                          <template #label>
                            <small>
                              Nej, alle krav er
                              <span class="font-weight-bold">ikke</span>
                              opfyldt
                            </small>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="3" :complete="false" />
                    </v-col>
                    <v-col>
                      <OfferDetailsESPD
                        :tender
                        :offer="null"
                        :disabled="true"
                        :espdFile="null"
                      />
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="4" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                      <p>Færdiggør trin 1, 2 og 3, for at sende dit tilbud</p>
                      <v-dialog persistent scrollable max-width="80%">
                        <template #activator="{ props }">
                          <ArrowButton
                            disabled
                            v-bind="props"
                            class="mt-2"
                          >Se og send tilbud</ArrowButton>
                        </template>
                        <v-card> </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <TcoCalculationSummary
            :questions
            :tcoCalculationResult
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
        </v-col>
      </v-row>
    </v-container>
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  Tender,
  OfferSandboxBase,
  OfferDetailsForm,
  useBackend,
  QuestionnaireRoot,
  usePreviewOfferQuestionnaire,
  DeepQuestion,
  QuestionTypesEnum,
} from '@dims/components';
import TcoCalculationSummary from './OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import {
  setSupplierPriceTab,
} from '../../services/questionnaireTabConfig';
import excludedQuestions from '@/services/offerSummaryConfig';

const { tender } = defineProps<{ tender: Tender }>();
const backend = useBackend();
const offerFormDialog = ref(false);
const offerQuestionnaire = ref<QuestionnaireRoot>();
const priceSection = ref(3);

async function createSandboxOfferQuestionnaire() {
  offerQuestionnaire.value = await usePreviewOfferQuestionnaire(
    tender.id,
    backend,
  );
  priceSection.value = setSupplierPriceTab(
    offerQuestionnaire.value.questionnaire,
  );
}

const tcoCalculationResult = computed(
  () => offerQuestionnaire.value?.questionnaire.tcoCalculationResult,
);

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const questions = computed(() =>
{
  const section = offerQuestionnaire.value?.questionnaire.questions[priceSection.value];
  const subQuestions: Record<string, DeepQuestion[]> = {};
  if (section?.questionType === QuestionTypesEnum.REPEATER)
  section.items?.forEach(s => {subQuestions[s.title ?? ''] = s.questions.filter((q) => !excludedQuestions.some((x) => q.questionId.endsWith(x)));});
  else subQuestions[''] = section?.questions?.filter((q) => !excludedQuestions.some((x) => q.questionId.endsWith(x))) ?? [];
  return subQuestions;
});

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}
</script>
