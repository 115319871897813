/** Specifies IDs from the delivery agreement
 * Question IDs are named 'id' + a descriptive name for the question
 * Answer IDs are named 'id' + a descriptive name for the question
 * + 'Answer' + a descriptive name for the answer
 * Some IDs can be accessed through corresponding enums
*/
const deliveryAgreementIds = {
  idDeliveryDate: '8b238ee9-9354-41ae-90ce-6401b6bf4a21',
  idDeliveryDaysAfterContract: '2cefcfdd-77f9-4264-ab2f-a3dc50c0c10a',
  idDeadlineQuestions: 'd40dc832-de12-482b-92e1-d8af76fb4cc8',
  // Criteria weights:
  idDriverSeatWeight: '5647d861-0ae3-403a-be9f-7e5f5ef3f806',
  idEnterExitWeight: 'ce05a3e2-5554-46ea-bb6e-6da4adf3129f',
  idInstrumentationWeight: '3e42970a-95e5-4137-a3eb-44c3509b4003',
  idDrivingWeight: '7f6c6265-b1bb-4a7b-8e3d-439a3b8da181',
  idComfortWeight: 'cb899e42-2c56-45b7-bcd0-7b5d67ac6893',
  idViewWeight: 'd1ab5124-56b2-4e0b-bbed-2ba1a55cf719',
  idNoiseWeight: '6d8bc154-f927-44df-8827-ff8a3195bcac',
  idSecurityWeight: '1b27ab5c-4b3b-40de-93f2-87bb13c72115',
};
export default deliveryAgreementIds;
