import { TemplateQuestionPanelState, Tab, TabType, Questionnaire } from '@dims/components';

const customerSpecificationTabs: Tab[] = [{
  title: 'Basis',
  section: 0,
  panelState: TemplateQuestionPanelState.INIT,
},
{
  title: 'Puljekøb',
  section: 1,
  panelState: TemplateQuestionPanelState.INIT,
},
{
  title: 'Vilkår',
  section: 2,
  panelState: TemplateQuestionPanelState.INIT,
},
];

function supplierOfferTabs(p: number) {
  return [
    {
      title: 'Afgiv priser',
      section: p,
      panelState: TemplateQuestionPanelState.INIT,
    },
  ];
}

const priceSectionId = '3d6109c4-b01f-4ef2-97d4-a1058e6840fc'; // Tilbudsliste
let supplierOfferTabNumber = 3;

export function setSupplierSpecificationTabs(_: Questionnaire|null):number[] {
  return [];
}

export function setSupplierPriceTab(offerQuestionnaire: Questionnaire|null):number {
  supplierOfferTabNumber = offerQuestionnaire?.questions.findIndex((q) => q.questionId === priceSectionId) ?? 3;
  return supplierOfferTabNumber;
}

const questionnaireTabConfig = {
  getTabs: (tenderId: string, type: TabType): Promise<Tab[]> => {
    let tabs: Tab[] = [];

    switch (type) {
      case 'CustomerSpecification':
        tabs = customerSpecificationTabs;
        break;
      case 'SupplierSpecification':
        tabs = [];
        break;
      case 'SupplierOffer':
        tabs = supplierOfferTabs(supplierOfferTabNumber);
        break;
    }

    return new Promise((resolve) => {
      resolve(tabs);
    });
  },
};

export default questionnaireTabConfig;
